import { Grid2, Stack } from '@mui/material';
import { Season } from 'src/api';
import { CompetitionLeaguesSelect } from 'src/components/app/CompetitionLeaguesSelect';
import { SeasonsSelectSmall } from 'src/components/app/SeasonsSelectSmall';
import { ExportButton } from 'src/components/ExportButton';
import { useFilterState } from 'src/hooks/useFilterState';
import { useExportDashboardToExcel } from './dashboard-export';
import { DashboardDiscount } from './DashboardDiscount';
import { DashboardForecast } from './DashboardForecast';
import { DashboardInventory } from './DashboardInventory';
import { DashboardKeyRight } from './DashboardKeyRight';
import { DashboardOffer } from './DashboardOffer';
import { DashboardPartnerAndContracts } from './DashboardPartner';

export interface DashboardParams {
  seasonId: string;
  leagueIds: string[];
}

const defaultFilterState = {
  seasonId: '',
  leagueIds: [],
};

export function Dashboard() {
  const [filters, updateFilters] = useFilterState<DashboardParams>('dashboard', defaultFilterState);
  const exportToExcel = useExportDashboardToExcel(filters.seasonId, filters.leagueIds);

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={3}>
        <ExportButton onClick={exportToExcel} sx={{ ml: 2 }} />
        <SeasonsSelectSmall
          value={filters.seasonId}
          onChange={(season: Season) => updateFilters('seasonId', season.id)}
        />
        <CompetitionLeaguesSelect
          multiple
          size="small"
          activeLeagues={filters.leagueIds}
          onChange={(newLeagueIds) => updateFilters('leagueIds', newLeagueIds)}
        />
      </Stack>

      {filters.leagueIds?.length > 0 && filters.seasonId && (
        <Grid2 container spacing={3} pt={3} alignItems="stretch">
          <Grid2 size={{ xs: 12, md: 6, lg: 4 }}>
            <DashboardForecast {...filters} />
          </Grid2>

          <Grid2 size={{ xs: 12, md: 6, lg: 2 }}>
            <DashboardDiscount {...filters} />
          </Grid2>

          <Grid2 size={{ xs: 12, md: 6, lg: 4 }}>
            <DashboardInventory {...filters} />
          </Grid2>

          <Grid2 size={{ xs: 12, md: 6, lg: 2 }}>
            <DashboardPartnerAndContracts {...filters} />
          </Grid2>

          <Grid2 size={{ xs: 12, md: 6, lg: 4 }}>
            <DashboardOffer {...filters} />
          </Grid2>

          <Grid2 size={{ xs: 12, md: 6, lg: 8 }}>
            <DashboardKeyRight {...filters} />
          </Grid2>
        </Grid2>
      )}
    </>
  );
}
