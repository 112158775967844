import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { identity } from 'lodash-es';
import { useAuth } from 'src/contexts/AuthProvider';
import { useSessionStorageState } from './useSessionStorageState';

export function useFilterState<T = any>(
  key: string,
  initialValue: T,
  parsers?: Record<string, (input: any) => any>,
): [typeof state, typeof updateState] {
  const router = useRouter();
  const { accountId } = useAuth();
  const [state, setState] = useSessionStorageState<T>(`filters-${accountId}-${key}`, initialValue);

  const updateState = useCallback((name: string, newState: any) => {
    setState((currentState: any) => ({ ...currentState, [name]: newState }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (router.isReady && initialValue) {
      Object.keys(initialValue)
        .filter((key) => !!router.query[key])
        .forEach((key) => {
          const parser = parsers?.[key] ?? identity;
          const value = router.query[key];
          updateState(key, parser(value));
        });
    }
  }, [router.isReady]); // eslint-disable-line react-hooks/exhaustive-deps

  return [state, updateState];
}
