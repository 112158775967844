import { useEffect, useState } from 'react';
import { safeJsonParse } from './useLocalStorageState';

export function useSessionStorageState<T = any>(
  key: string,
  initialValue: T,
): [typeof state, typeof setState] {
  const [state, setState] = useState<T>(
    () => (safeJsonParse(sessionStorage?.getItem(key)) as T) ?? initialValue,
  );

  useEffect(() => {
    sessionStorage?.setItem(key, JSON.stringify(state));
  }, [state, key]);

  return [state, setState];
}
