import { useEffect, useState } from 'react';

type JsonReviver = (this: any, key: string, value: any) => any; // from lib.es5.d.ts

export function safeJsonParse(string: string | null, defaultValue?: any, reviver?: JsonReviver) {
  try {
    return JSON.parse(string as string, reviver);
  } catch {
    return defaultValue;
  }
}

export function useLocalStorageState<T = any>(key: string, initialValue: T): [typeof state, typeof setState] {
  const [state, setState] = useState<T>(
    () =>
      (typeof localStorage !== 'undefined' ? (safeJsonParse(localStorage?.getItem(key)) as T) : null) ??
      initialValue,
  );

  useEffect(() => {
    localStorage?.setItem(key, JSON.stringify(state));
  }, [state, key]);

  return [state, setState];
}
